import React from 'react'

import { Head } from '../../components/Head'
import { ArticleExample } from '../../examples/html/pages/ArticleExample'

const Page = () => (
  <>
    <Head title="Artikkeli" />
    <ArticleExample />
  </>
)

export default Page
